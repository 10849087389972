var stickyHeader = true;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
var countUpStats = true;
var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links

// Bespoke Footer Box before Footer
$('.footerBox4').insertBefore('.pageFooter');

// Carousel with video
if ($('li.carouselSlide').hasClass('containsVideo')) {
    $('body').addClass('page-has-video-banner');
}

// Mixed module listing page - Team page
$(".mixedModuleList .listedItem.PostCategory_senior-leadership-team").wrapAll("<div class='mixedPostsSeniorLeadershipTeam'></div>");
$(".mixedModuleList .listedItem.PostCategory_staff").wrapAll("<div class='mixedPostsStaff'></div>");
$(".mixedModuleList .listedItem.PostCategory_chair-of-the-board").wrapAll("<div class='mixedPostsChairOfTheBoard'></div>");
$(".mixedModuleList .listedItem.PostCategory_trustees").wrapAll("<div class='mixedPostsTrustees'></div>");
$(".mixedModuleList .listedItem.PostCategory_patrons-ambassadors").wrapAll("<div class='mixedPostsPatronsAmbassadors'></div>");
$(".mixedModuleList .listedItem.PostCategory_medical-advisors").wrapAll("<div class='mixedPostsMedicalAdvisors'></div>");
$(".mixedModuleList .listedItem.PostCategory_volunteers").wrapAll("<div class='mixedPostsVolunteers'></div>");
$(".mixedModuleList .listedItem.PostCategory_join-the-team").wrapAll("<div class='mixedPostsJoinTheTeam'></div>");

// Mixed module listing page - Research page
$(".mixedModuleList .listedItem.PostCategory_current-research").wrapAll("<div class='mixedPostsCurrentResearch'></div>");
$(".mixedModuleList .listedItem.PostCategory_completed-research").wrapAll("<div class='mixedPostsCompletedResearch'></div>");
$(".mixedModuleList .listedItem.PostCategory_volunteer-research").wrapAll("<div class='mixedPostsVolunteerResearch'></div>");

// Mixed module listing page - Events page
$(".mixedModuleList .listedItem.PostCategory_past-events").wrapAll("<div class='mixedPostsPastEvents'></div>");
$(".mixedModuleList .listedItem.PostCategory_upcoming-events").wrapAll("<div class='mixedPostsUpcomingEvents'></div>");

// Mixed module listing page - Frequently asked questions
$(".mixedModuleList .listedItem.PostCategory_information-and-support-faqs").wrapAll("<div class='mixedPostsInformationAndSupport'></div>");
$(".mixedModuleList .listedItem.PostCategory_dystonia-uk-faqs").wrapAll("<div class='mixedPostsDystoniaUK'></div>");

// Mixed module listing page - info-hub
$(".mixedModuleList .listedItem.PostCategory_dystonia-matters-webinars").wrapAll("<div class='mixedPostsDystoniaMattersWebinars'></div>");
$(".mixedModuleList .listedItem.PostCategory_dystonia-matters-podcast").wrapAll("<div class='mixedPostsDystoniaMattersPodcast'></div>");
$(".mixedModuleList .listedItem.PostCategory_dystonia-animated").wrapAll("<div class='mixedPostsDystoniaAnimated'></div>");
$(".mixedModuleList .listedItem.PostCategory_the-booklet-bank").wrapAll("<div class='mixedPostsTheBookletBank'></div>");